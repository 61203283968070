const initialState = {
    bannersV2: [],
    navBarButtons: [],
    departmentsV2: [],
    subDepartmentsV2: [],
    categoriesV2s: [],
    keellsOwnLabelItems: [],
    dealsProductList: [],
    pageSections: []
};

export const webDataCollectionV2Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_WEB_DATA_COLLECTION_V2':
            return {
                bannersV2: action.payload.bannerV2s,
                navBarButtons: action.payload.navBarButtons,
                departmentsV2: action.payload.departmentV2s,
                subDepartmentsV2: action.payload.subDepartmentV2s,
                categoriesV2s: action.payload.categoryV2s,
                keellsOwnLabelItems: action.payload.keellsOwnLabelItems,
                dealsProductList: action.payload.dealsProductsList,
                pageSections: action.payload.pageSections
            };
        case 'RESET_WEB_DATA_COLLECTION_V2':
            return initialState;
        default:
            return state;
    }
};
