const initialState = {
    pageCampaignBanner: null,
    pageCampaignItems: [],
};

export const pageCampaignDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PAGE_CAMPAIGN_DATA':
            return {

                pageCampaignBanner: action.payload.banner,
                pageCampaignItems: action.payload.itemDetails
            };

        case 'RESET_PAGE_CAMPAIGN_DATA':
            return initialState;
        default:
            return state
    }
};
