export const OrderTypesEnum = Object.freeze({
    ItemOrder: 1,
    UtilityPayment: 2,
})

export const LocalStorageEnum = Object.freeze({
    AppVersion: "AppVersion",
    OrderNumber: "OrderNumber",
    OrderTotal: "OrderTotal",
    ErrorObject: "ErrorObject",
    ErrorCustomerID: "ErrorCustomerID",
    ErrorTimeStamp: "ErrorTimeStamp",
    AMEXSessionID: "AMEXSessionID",
    IsSaveCard: "IsSaveCard",
    SessionID: "SessionID",
    PaymentMethod: "PaymentMethod",
    IsUtility:"IsUtility"
})

export const LandingPageViewEnum = Object.freeze({
    WelcomeView: "WelcomeView",
    LoginView: "Login",
    RegistrationView: "Registration",
    TrackOrderView: "TrackOrder",
    PreferenceView: "Preference",
    ForgetPasswordView: "ForgetPassword",
    ContinueShoppingAfterUtilityPayment: "ContinueShoppingAfterUtilityPayment"
})

export const WelcomeTileTypesEnum = Object.freeze({
    LoginTile: "LoginTile",
    RegisterTile: "RegisterTile",
    TrackOrderTile: "TrackOrderTile",
    BrowseStoreTile: "BrowseStoreTile",
    ContactUsExternal: "ContactUsExternal",
    DonationModuleTile: "DonationModuleTile",
})

export const errorTypesEnum = Object.freeze({
    error: 1,
    information: 2,
    notification: 3,
    success: 4,
    warning: 5,
})

export const productListingTypeEnum = Object.freeze({
    listByDepartment: "1",
    listBySubDepartment: "2",
    listByCategory: "3",
    listByProductName: "4",
    listByPromotion: "5",
    listAllPromotions: "6",
    listAllBrand: "7",
})

export const productSearchLengthEnum = Object.freeze({
    productSearchLength: 0
})

export const PaymentMethods = Object.freeze({
    VisaMaster: 1,
    Amex: 2,
})

export const shoppingMethod = Object.freeze({
    pickup: "Pickup",
    delivery: "Delivery",
})

export const otherPromotionTypesEnum = Object.freeze({
    KeellsDeals: 1,
    NexusDeals: 2,
    BuyMoreSaveMore: 3,
    AllPromotionFeatuedProduct: 4,
    TotalBillPromotion: 5
})

export const utilityPaymentTab = Object.freeze({
    utilityPayments: 1,
    myTemplates: 2,
    transcations: 3
})

export const utilityPaymentView = Object.freeze({
    categoryView: 1,
    subCategoryView: 2,
    formView: 3
})

export const responsiveScreenSizesEnum = Object.freeze({
    tabScreenSize: 800,
    mobileLargeScreen: 430,
    mobileSmallScreen: 375,
})

export const newNexusRegistrationCodeEnum = Object.freeze({
    newNexusRegistrationCode: 222222
})

export const myAccountViews = Object.freeze({
    profileEdit: 1,
    changePassword: 2,
    trackOrder: 3,
    utilityPayments: 4,
    savedCarts: 5,
    pastOrders: 6,
    shippingDetails: 7,
    refundOption: 8,
    savedCards: 9
})

export const orderPrefixes = Object.freeze({
    UtilityBillPayment: "UBP"
})

export const brandPromoEnum = Object.freeze({
    department: "1",
    subdepartment: "2",
    category: "3",
    brand: "4",
    item: "5",
    group: "6"
})

export const showcaseDefaultsEnum = Object.freeze({
    locationCode: "",
})

export const paymentModes = Object.freeze({
    cash: "Cash",
    card: "Card",
    nexus: "Nexus"
})

export const thankYouPageEnum = Object.freeze({
    order: 1,
    utilityPayment: 2,
    eBillSubscription: 3,
    eBillUnsubscription: 4,
    eBillChangeEmail: 5,
    donated: 6,
    billMeSubscription: 7,
    billMeUnsubscription: 8,
    unionAssuranceSubscription: 9,
    unionAssuranceSubscriptionExpired: 10
})

export const experienceEnum = Object.freeze({
    onlineExperience: "1",
    deliveryExperience: "2",
    pickupExperience: "3"
})

export const cybersourceCardTypes = Object.freeze({
    visa: "001",
    mastercard: "002"
})
export const homePageType = Object.freeze({
    onlinestore: 1,
    keells: 2,
    promotions: 3
})
export const JKOAMerchantTypes = Object.freeze({
    JKOA: 2
})
export const ebillActionTypeEnum = Object.freeze({
    subscribe: 1,
    unsubscribe: 2,
    changeEmail: 3
})

export const PersonalizedPromotionTypes = Object.freeze({
    PersonalizedPromotion: 1,
    ChurnWithLink: 2,
    ChurnWithoutLink: 3,
})

export const DonationData = Object.freeze({
    PaymentModeID: 69,
    AccountNumber: 1500,
})

export const AllPromotionDefaultsEnum = Object.freeze({
    locationCode: "SCUP",
})

export const BannerTypeEnum = Object.freeze({
    AllPromotionCarousel: 7,
    AllPromotionTile1: 8,
    AllPromotionTile2: 9,
    AllPromotionTile3: 10,
    AllPromotionTile4: 11,
    AllPromotionSupplier: 12,
    OnlineExclusiveBanner1: 13,
    OnlineExclusiveBanner2: 14,
    OnlineExclusiveBanner3: 15,
    OnlineExclusiveBanner4: 16,
    OnlineExclusiveBanner5: 17,
    OnlineExclusiveBanner6: 18,
    OnlineExclusiveBanner7: 19,
    OnlineExclusiveBanner8: 20,
    InStoreExclusiveBanner1: 21,
    InStoreExclusiveBanner2: 22,
    InStoreExclusiveBanner3: 23,
    InStoreExclusiveBanner4: 24,
    InStoreExclusiveBanner5: 25,
    InStoreExclusiveBanner6: 26,
    InStoreExclusiveBanner7: 27,
    InStoreExclusiveBanner8: 28,
    BankPromotions: 29,
    AllPromotionFeaturedProductsBanner: 30,
    AllPromotionNexusDealsBanner: 31,
    AllPromotionKeellsDealsBanner: 32
})

export const ExclusivePromotionTypeEnum = Object.freeze({
    OnlineExclusive: 1,
    InStoreExclusive: 2,
})

export const PagesEnum = Object.freeze({
    HomePage: 1,
    CategoryPage: 2,
    ShopBySubCategory: 3,
    SearchPage: 4,
    CartPage: 5,
    ProductDetailPage: 6
})