const initialState = {
    accountType: 1
};

export const myAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MY_ACCOUNT_TYPE':
            return {
                accountType: action.payload
            };
        default:
            return state;
    }
};