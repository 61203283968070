import { combineReducers } from 'redux';
import { messageBoxReducer } from './reducers/messageBoxReducer';
import { notificationBoxReducer } from './reducers/notificationBoxReducer';
import { responsiveReducer } from './reducers/responsiveReducer';
import { loadingScreenReducer } from './reducers/loadingScreenReducer';
import { webDataCollectionReducer } from './reducers/webDataCollectionReducer';
import { promotionItemsReducer } from './reducers/promotionItemsReducer';
import { userDetailReducer } from './reducers/userDetailsReducer';
import { preferenceReducer } from './reducers/preferenceReducer';
import { itemCartReducer } from './reducers/itemCartReducer';
import { sessionTimeoutReducer } from './reducers/sessionTimeoutReducer';
import { utilityDataCollectionReducer } from './reducers/utilityDataCollectionReducer';
import { utilityCartReducer } from './reducers/utilityCartReducer';
import { trackOrderDetailReducer } from './reducers/trackOrderDetailReducer';
import { nexusRegistrationDetailReducer } from './reducers/nexusRegistrationDetailReducer';
import { popupBoxReducer } from './reducers/popupBoxReducer';
import { productMagnifierReducer } from './reducers/productMagnifierReducer';
import { orderPromotionReducer } from './reducers/orderPromotionReducer';
import { orderFreeIssuePromotionReducer } from './reducers/orderFreeIssuePromotionReducer';
import { ageVerificationReducer } from './reducers/ageVerificationReducer';
import { ageGateReducer } from './reducers/ageGateReducer';
import { nexusRedemptionReducer } from './reducers/nexusRedemptionReducer';
import { insideNotificationStripeReducer, outsideNotificationStripeReducer } from './reducers/notificationStripeReducer';
import { preferenceModalReducer } from './reducers/preferenceModalReducer';
import { userRedirectReducer } from './reducers/userRedirectReducer';
import { calculatedPromotionResultReducer } from './reducers/calculatedPromotionResultReducer';
import { queueManagerReducer } from './reducers/queueManagerReducer';
import { jkoaPurchaseReducer } from './reducers/jkoaPurchaseReducer';
import { checkoutReducer } from './reducers/checkoutReducer';
import { welcomePageTilesReducer } from './reducers/welcomePageTilesReducer'
import { orderDataReducer } from './reducers/orderDataReducer'
import { promoCodeReducer } from './reducers/promoCodeReducer'
import { algonomyReducer } from './reducers/algonomyReducer'
import { nexusReminderNotificationBoxReducer } from './reducers/V2_0/nexusReminderNotificationBoxReducer';
import { latLongForLoyaltyReducer } from './reducers/V2_0/latLongForLoyaltyReducer';

import { personalizationDataReducer } from './reducers/v2.0/personalizationDataReducer'
import { webDataCollectionV2Reducer } from './reducers/v2.0/webDataCollectionReducer'
import { pageCampaignDataReducer } from './reducers/v2.0/pageCampaignDataReducer'

/** Persist redux state on refresh */
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { loyaltyRegistrationDetailReducer } from './reducers/V2_0/loyaltyRegistrationDetailReducer';
import { signInDropDownReducer } from './reducers/v2.0/signInDropDownReducer';
import { myAccountReducer } from './reducers/v2.0/myAccountReducer';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: [/**'Prevent persist states'*/]
}

/**
 * Note: When you add new reducers, 
 * please add them to relevant reset function of those.
 * 
 * File : storeResetFunction
 * 
 */

const storeReducer = combineReducers({
    messageBox: messageBoxReducer,
    notificationBox: notificationBoxReducer,
    loadingScreen: loadingScreenReducer,
    responsive: responsiveReducer,
    webDataCollection: webDataCollectionReducer,
    promotionItems: promotionItemsReducer,
    userDetails: userDetailReducer,
    shoppingPreference: preferenceReducer,
    itemCart: itemCartReducer,
    sessionTimeout: sessionTimeoutReducer,
    utilityDataCollection: utilityDataCollectionReducer,
    utilityCart: utilityCartReducer,
    trackOrderDetail: trackOrderDetailReducer,
    nexusRegistrationDetail: nexusRegistrationDetailReducer,
    popupBox: popupBoxReducer,
    productMagnifier: productMagnifierReducer,
    orderPromotionDetails: orderPromotionReducer,
    orderFreeIssuePromotionDetails: orderFreeIssuePromotionReducer,
    ageVerification: ageVerificationReducer,
    ageGate: ageGateReducer,
    nexusRedemption: nexusRedemptionReducer,
    insideNotificationStripe: insideNotificationStripeReducer,
    outsideNotificationStripe: outsideNotificationStripeReducer,
    preferenceModal: preferenceModalReducer,
    orderPromotionDetails: orderPromotionReducer,
    userRedirect: userRedirectReducer,
    calculatedPromotionResult: calculatedPromotionResultReducer,
    queueManager: queueManagerReducer,
    jkoaPurchase: jkoaPurchaseReducer,
    checkout: checkoutReducer,
    welcomePageTiles: welcomePageTilesReducer,
    orderData: orderDataReducer,
    promoCodeReducer: promoCodeReducer,
    algonomyData: algonomyReducer,
    signInDropDown: signInDropDownReducer,
    myAccountType: myAccountReducer,
    personalizationData: personalizationDataReducer,
    webDataCollectionV2: webDataCollectionV2Reducer,
    pageCampaignDetails: pageCampaignDataReducer,
    nexusReminderNotificationBox: nexusReminderNotificationBoxReducer,
    loyaltyRegistrationDetail: loyaltyRegistrationDetailReducer,
    latLongForLoyalty: latLongForLoyaltyReducer,
})

export default persistReducer(persistConfig, storeReducer);