const initialState = {
    show: false
};

export const signInDropDownReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_DROP_DOWN':
            return {
                show: action.payload,
            };
        case 'RESET_DROP_DOWN':
            return initialState;
        default:
            return state;
    }
};